import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import useCurrentFarm from '@modules/encoding/shared/hooks/useCurrentFarm';
import entities from '@shared/entities';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { SelectChangeEvent } from '@soil-capital/ui-kit/material-core';

export const useSelectSeasonsLogic = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { currentFarm } = useCurrentFarm();
    const { seasonState } = entities.season.useState({ farmId: currentFarm?.id }, { skip: !currentFarm });
    const { staticSeasonState } = entities.staticSeason.useState();

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const getFsHarvestYear = (farmSeasonId: number) => {
        const farmSeason = seasonState.getById(farmSeasonId);
        if (!farmSeason) return '';

        const staticSeason = staticSeasonState?.getById(farmSeason.season_id);
        if (!staticSeason) return '';

        return staticSeason.harvest_year;
    };

    const options = seasonState.list
        .map((season) => ({
            ...season,
            harvest_year: staticSeasonState?.getById(season.season_id)?.harvest_year,
        }))
        .sort((a, b) => (a.harvest_year && b.harvest_year ? a.harvest_year - b.harvest_year : 0));

    const handleSeasonChanged = (v: SelectChangeEvent<unknown>) => {
        const path = pathname.replace(`${currentSeasonId}`, `${v.target.value}`);
        navigate(path);
    };

    const isLoading = seasonState.isLoading || staticSeasonState.isLoading;
    const defaultValue = options.length ? currentSeasonId.toString() : '';

    return {
        getFsHarvestYear,
        options,
        handleSeasonChanged,
        isLoading,
        defaultValue,
    };
};
