import {
    type FarmProfileStaticDataT,
    type FarmSeasonProfileT,
    type FarmSeasonProfileUpdatePayloadT,
} from '@modules/encoding/modules/farmProfile/api/farmProfileApi.types';
import { type FarmProfileStaticDataTypeT } from '@modules/encoding/modules/farmProfile/farmProfile.types';
import api from '@shared/api';

export const farmSeasonProfileApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getFarmSeasonProfile: builder.query<FarmSeasonProfileT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/profile`,
                method: 'GET',
            }),
            providesTags: ['FarmSeasonProfile'],
        }),
        getFarmSeasonProfileStaticDataByType: builder.query<
            FarmProfileStaticDataT[],
            { type: FarmProfileStaticDataTypeT }
        >({
            query: ({ type }) => ({
                url: `/v3/static/static-data/${type}`,
                method: 'GET',
            }),
        }),
        createFarmSeasonProfile: builder.mutation<
            FarmSeasonProfileT,
            { farmSeasonId: number; payload: FarmSeasonProfileUpdatePayloadT }
        >({
            query: ({ farmSeasonId, payload }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/profile`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['FarmSeasonProfile', 'Progress'],
        }),
        updateFarmSeasonProfile: builder.mutation<
            FarmSeasonProfileT,
            { farmSeasonId: number; farmSeasonProfileId: number; payload: FarmSeasonProfileUpdatePayloadT }
        >({
            query: ({ farmSeasonId, farmSeasonProfileId, payload }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/profile/${farmSeasonProfileId}`,
                method: 'PATCH',
                body: payload,
            }),
            invalidatesTags: ['FarmSeasonProfile', 'Progress', 'Livestock', 'Irrigation', 'Field'],
        }),
        deleteFarmSeasonProfileStaticData: builder.mutation<
            FarmSeasonProfileT,
            {
                farmSeasonId: number;
                farmSeasonProfileId: number;
                static_data_ids: number[];
            }
        >({
            query: ({ farmSeasonId, farmSeasonProfileId, static_data_ids }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/profile/${farmSeasonProfileId}/static-data`,
                method: 'DELETE',
                body: {
                    static_data_ids,
                },
            }),
            invalidatesTags: ['FarmSeasonProfile', 'Progress'],
        }),
    }),
});

export const {
    useGetFarmSeasonProfileQuery,
    useGetFarmSeasonProfileStaticDataByTypeQuery,
    useCreateFarmSeasonProfileMutation,
    useUpdateFarmSeasonProfileMutation,
    useDeleteFarmSeasonProfileStaticDataMutation,
} = farmSeasonProfileApi;
