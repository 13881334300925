import { NavigateFunction } from 'react-router-dom';
import irrigationRoutes from '../../irrigation.routes';
import { useIrrigationRouteParams } from '../../hooks/useIrrigationParams';
import entities, {
    StaticIrrigationMethodT,
    StaticIrrigationPowerSourceT,
    StaticIrrigationWaterSourceT,
} from '@shared/entities';
import { useTranslation } from 'react-i18next';
import { FormStaticDataT, IrrigationFormValuesT } from '../../components/IrrigationForm/IrrigationForm';
import { useStableDebounce } from '@shared/hooks/useStableDebounce';
import { FORM_SUBMIT_DEBOUNCE_TIME_MS } from '@shared/constants/formsConfig';
import { useMemo } from 'react';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';

export const useIrrigationFormActionPageLogic = ({ navigate }: { navigate: NavigateFunction }) => {
    const { irrigationId, seasonId } = useIrrigationRouteParams('irrigationForm');
    const { readOnly } = useFarmSeasonReadOnly();
    const { fieldState } = entities.field.useState({ farmSeasonId: seasonId });
    const { t } = useTranslation();

    if (!seasonId || !irrigationId) {
        throw Error('A param is unexpectedly missing in the irrigation form route');
    }

    const { staticIrrigationMethodState } = entities.staticIrrigationMethod.useState();
    const { staticIrrigationPowerSourceState } = entities.staticIrrigationPowerSource.useState();
    const { staticIrrigationWaterSourceState } = entities.staticIrrigationWaterSource.useState();
    const { irrigationState } = entities.irrigation.useState({ farmSeasonId: seasonId });
    const [updateIrrigation] = entities.irrigation.useUpdate();

    const irrigation = irrigationState.getById(irrigationId);
    const currentField = fieldState.getById(irrigation?.farm_season_field_id);
    const isLoading =
        staticIrrigationMethodState.isLoading ||
        staticIrrigationPowerSourceState.isLoading ||
        staticIrrigationWaterSourceState.isLoading ||
        irrigationState.isLoading;

    const currentIrrigationData = irrigationState.getById(irrigationId);

    const initialFormValues = useMemo(
        () => {
            if (currentIrrigationData?.id)
                return {
                    horizontalDistance: currentIrrigationData?.horizontal_distance,
                    irrigationMethodId: currentIrrigationData?.irrigation_method_id,
                    irrigationPowerSourceId: currentIrrigationData?.irrigation_power_source_id,
                    irrigationWaterSourceId: currentIrrigationData?.irrigation_water_source_id,
                    pumpingDepth: currentIrrigationData?.pumping_depth,
                    waterAmount: currentIrrigationData?.total_amount,
                };
            return null;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentIrrigationData?.id],
    );

    const _getSelectOptionItem = (
        item: StaticIrrigationMethodT | StaticIrrigationPowerSourceT | StaticIrrigationWaterSourceT,
    ) => ({ value: item.id, label: t(item.translation_slug) });

    const irrigationStaticData: FormStaticDataT = {
        methodsList: staticIrrigationMethodState.list.map(_getSelectOptionItem),
        powerSourceList: staticIrrigationPowerSourceState.list.map(_getSelectOptionItem),
        waterSourceList: staticIrrigationWaterSourceState.list.map(_getSelectOptionItem),
    };

    const handleFormChange = useStableDebounce((formValues: IrrigationFormValuesT) => {
        updateIrrigation({
            farmSeasonId: seasonId,
            id: irrigationId,
            body: {
                horizontal_distance: formValues.horizontalDistance,
                irrigation_method_id: formValues.irrigationMethodId,
                irrigation_power_source_id: formValues.irrigationPowerSourceId,
                irrigation_water_source_id: formValues.irrigationWaterSourceId,
                pumping_depth: formValues.pumpingDepth,
                total_amount: formValues.waterAmount,
            },
        });
    }, FORM_SUBMIT_DEBOUNCE_TIME_MS);

    const handleBackClick = () => {
        navigate(irrigationRoutes.introduction());
    };

    const handleContinueClick = () => {
        if (irrigationState.list.length > 1 && !readOnly) {
            navigate(irrigationRoutes.irrigationDuplication({ irrigationId }));
        } else {
            navigate(irrigationRoutes.introduction());
        }
    };

    const continueDisabled = useMemo(() => {
        return (
            currentIrrigationData?.horizontal_distance === null ||
            currentIrrigationData?.irrigation_method_id === null ||
            currentIrrigationData?.irrigation_power_source_id === null ||
            currentIrrigationData?.irrigation_water_source_id === null ||
            currentIrrigationData?.pumping_depth === null ||
            currentIrrigationData?.total_amount === null
        );
    }, [currentIrrigationData]);

    return {
        fieldName: currentField?.name,
        handleBackClick,
        handleContinueClick,
        handleFormChange,
        isLoading,
        irrigationStaticData,
        initialFormValues,
        continueDisabled,
    };
};
