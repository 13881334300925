import { useRotationFieldFilters } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/hooks/useRotationFieldFilters';
import { useFieldCropAssignationModalRightPanelViewModel } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/FieldCropAssignationModalRightPanel/useFieldCropAssignationModalRightPanelViewModel';
import { useWatch } from 'react-hook-form';
import { ROTATION_ASSIGNATION_INPUT_NAMES } from '../../hooks/useRotationAssignationForm';
import { useModalController } from '@shared/hooks/useModalController';
import { ReplaceCropModalCloseProps, ReplaceCropModalOpenProps } from '../ReplaceCropModal/ReplaceCropModal';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SharedStateT } from '@shared/store';
import { fieldSelectionAssignationSlice } from '@modules/encoding/shared/store/fieldSelectionSlice';

export const useFieldCropAssignationModalRightPanelLogic = ({ readOnly }: { readOnly: boolean }) => {
    const dispatch = useDispatch();
    const selectedFieldIds: number[] = useWatch({ name: ROTATION_ASSIGNATION_INPUT_NAMES.SELECTED_FIELD_IDS });
    const selectedCropId: number | null = useWatch({ name: ROTATION_ASSIGNATION_INPUT_NAMES.SELECTED_CROP_ID });
    const disabledFieldIds = useSelector((state: SharedStateT) => state.fieldSelectionAssignation.disabledIds);
    const loadingFieldIds = useSelector((state: SharedStateT) => state.fieldSelectionAssignation.loadingIds);

    const {
        harvestYear,
        fieldList,
        getFieldCrop,
        getFieldCropIdByCropIdAndFieldId,
        createFieldCrop,
        getHasOperationsByFarmSeasonFieldCropId,
        deleteFieldCrop,
        getFieldName,
        getStaticCropName,
        getFieldCropsByFieldId,
        fieldIds,
    } = useFieldCropAssignationModalRightPanelViewModel();

    const confirmDeleteFieldCropModal = useModalController<{ fieldName: string; staticCropName: string }>();
    const replaceCropModal = useModalController<ReplaceCropModalOpenProps, ReplaceCropModalCloseProps>();

    const {
        search,
        fieldMatchingFilter,
        selectedFilter,
        handleSearchChange,
        handleResetSearch,
        doesFieldMatchFilters,
        handleFilterChanged,
        handleFilterReset,
        handleFieldIdFilterReset,
    } = useRotationFieldFilters();

    // Sync fieldSelection data
    useEffect(() => {
        const loadingFieldIds = fieldList
            .filter((field) => {
                const selectedFieldCrop = getFieldCrop({
                    cropId: selectedCropId,
                    fieldId: field.id,
                });
                return Number(selectedFieldCrop?.id) <= 0;
            })
            .map((field) => field.id);
        const disabledFieldIds = !selectedCropId || readOnly ? fieldList.map((field) => field.id) : loadingFieldIds;

        dispatch(fieldSelectionAssignationSlice.actions.setSelectedFieldIds(selectedFieldIds));
        dispatch(fieldSelectionAssignationSlice.actions.setDisabledFieldIds(disabledFieldIds));
        dispatch(fieldSelectionAssignationSlice.actions.setLoadingFieldIds(loadingFieldIds));
    }, [dispatch, fieldList, getFieldCrop, readOnly, selectedCropId, selectedFieldIds]);

    const handleFieldSelection = useCallback(
        async (fieldId: number) => {
            if (!selectedCropId) {
                console.error('No crop selected');
                return;
            }
            const selectedFieldCropId = getFieldCropIdByCropIdAndFieldId({ cropId: selectedCropId, fieldId });

            // if selectedField exist in FE but not yet in DB (optimistic response), we stop action.
            if (Number(selectedFieldCropId) < 0) {
                return;
            }

            const otherCropLinkedToFields = getFieldCropsByFieldId({ fieldId });
            const cropCanBeReplaced = !selectedFieldCropId && otherCropLinkedToFields.length > 0;
            if (cropCanBeReplaced) {
                // the modal will add or replace the crop
                return replaceCropModal.open({
                    existingFieldCropIds: otherCropLinkedToFields.map((fieldCrop) => fieldCrop.id),
                    newCropId: selectedCropId,
                    newFieldId: fieldId,
                });
            }

            // if selectedField does not exist, we can create it
            if (!selectedFieldCropId) {
                createFieldCrop({
                    cropId: selectedCropId,
                    fieldId,
                });
                return;
            }

            // if selectedField exist in FE and DB delete it
            if (selectedFieldCropId > 0) {
                const hasOperationsLinked = getHasOperationsByFarmSeasonFieldCropId(selectedFieldCropId);
                if (!hasOperationsLinked) {
                    deleteFieldCrop(selectedFieldCropId);
                    return;
                }

                // fieldCrop has operations => ask confirmation to delete.
                if (
                    await confirmDeleteFieldCropModal.open({
                        fieldName: getFieldName(fieldId),
                        staticCropName: getStaticCropName(selectedCropId),
                    })
                ) {
                    deleteFieldCrop(selectedFieldCropId);
                    return;
                }
            }
        },
        [
            confirmDeleteFieldCropModal,
            createFieldCrop,
            deleteFieldCrop,
            getFieldCropIdByCropIdAndFieldId,
            getFieldCropsByFieldId,
            getFieldName,
            getHasOperationsByFarmSeasonFieldCropId,
            getStaticCropName,
            replaceCropModal,
            selectedCropId,
        ],
    );

    return {
        harvestYear,
        fieldList,
        search,
        fieldMatchingFilter,
        selectedFilter,
        handleSearchChange,
        handleResetSearch,
        doesFieldMatchFilters,
        handleFilterChanged,
        handleFilterReset,
        handleFieldIdFilterReset,
        handleFieldSelection,
        selectedCropId,
        confirmDeleteFieldCropModal,
        replaceCropModal,
        fieldIds,
        disabledFieldIds,
        selectedFieldIds,
        loadingFieldIds,
    };
};
