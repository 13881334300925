import { ReactElement, useRef } from 'react';
import { GuardT } from '@shared/guards/BaseGuard';
import { useGuardsRedirect } from '@shared/guards/useGuardsRedirect';
import { useLocation } from 'react-router-dom';

type GuardFunction = (props: GuardT) => ReactElement | null;

type Props = {
    element: ReactElement;
    guards: GuardFunction[];
    redirectTo?: string;
    loader?: ReactElement | null | false;
};

export const Guard = ({ element, guards, redirectTo, loader }: Props) => {
    const { pathname } = useLocation();
    const ref = useRef(pathname);
    const { redirectUrl } = useGuardsRedirect({ redirectTo });

    // This makes sure on redirection that previous page guards are not rendered
    // But this can create bug when you navigate in the same router..
    // It only works when you navigate to a different router as it creates a new guard instance with pathname === ref.current
    if (ref?.current !== pathname) return null;

    ref.current = pathname;

    const nestGuards = (index: number): ReactElement => {
        const GuardComponent = guards[index];

        if (index === guards.length - 1)
            return (
                <GuardComponent redirectUrl={redirectUrl} Loader={loader}>
                    {element}
                </GuardComponent>
            );

        return (
            <GuardComponent redirectUrl={redirectUrl} Loader={loader}>
                {nestGuards(index + 1)}
            </GuardComponent>
        );
    };

    if (guards.length === 0) return element;

    return nestGuards(0);
};
