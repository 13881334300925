import { Box, Skeleton, Typography, useTheme } from '@soil-capital/ui-kit/material-core';
import {
    IconMap2,
    IconFileQuestion,
    IconSeed,
    IconTractor,
    IconMicroscope,
    IconCoverCrop,
    IconLayersTwo1,
    IconFertilizer,
    IconCow,
    IconHistory,
} from '@soil-capital/ui-kit/icons';
import NavItem, { NavItemPropsT } from '../NavItem/NavItem';
import useEncodingNavStyle from './EncodingNav.style';
import useEncodingNavLogic from './useEncodingNavLogic';
import { useTranslation } from 'react-i18next';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import { useMemo } from 'react';
import NextSteps from '../NextSteps/NextSteps';
import { PROGRESS_SLUGS } from '@shared/entities/progress/progress.types';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { SelectSeasons } from '@modules/encoding/modules/layout/components/SelectSeasons/SelectSeasons';

export default () => {
    const { classes } = useEncodingNavStyle();
    const { currentSeasonId } = useCurrentSeasonId();
    const theme = useTheme();
    const skeletonBgColor = 'primaryScale.800';
    const { t } = useTranslation();

    const { isLoading, isBaseline, getProgressStepBySlug, getProgressStepStatus, computeSubtitle } =
        useEncodingNavLogic();

    const navItems = useMemo(() => {
        const navItemsArray: NavItemPropsT[] = [
            {
                status: getProgressStepStatus(PROGRESS_SLUGS.PREPARATION),
                title: t('encoding-layout.nav.preparation'),
                startIcon: <IconFileQuestion fontSize="small" style={{ color: theme.palette.common.white }} />,
                progress: getProgressStepBySlug('preparation')?.progress_percentage ?? 0,
                path: encodingRoutesConfig.PREPARATION,
                isVisible: getProgressStepBySlug('preparation')?.is_visible ?? false,
            },
            {
                status: getProgressStepStatus(PROGRESS_SLUGS.FARM_PROFILE),
                title: t('encoding-layout.nav.farm-profile'),
                startIcon: <IconTractor fontSize="small" style={{ color: theme.palette.common.white }} />,
                progress: getProgressStepBySlug('farm-profile')?.progress_percentage ?? 0,
                path: encodingRoutesConfig.FARM_PROFILE,
                isVisible: getProgressStepBySlug('farm-profile')?.is_visible ?? false,
            },
            {
                status: getProgressStepStatus(PROGRESS_SLUGS.ROTATION),
                title: t('encoding-layout.nav.rotation'),
                startIcon: <IconMap2 fontSize="small" style={{ color: theme.palette.common.white }} />,
                progress: getProgressStepBySlug('rotation')?.progress_percentage ?? 0,
                path: encodingRoutesConfig.ROTATION,
                isVisible: getProgressStepBySlug('rotation')?.is_visible ?? false,
            },
            {
                status: getProgressStepStatus(PROGRESS_SLUGS.TECHNICAL_ITINERARY),
                title: t('encoding-layout.nav.technical-itinerary'),
                subtitle: computeSubtitle('technical-itinerary'),
                startIcon: <IconSeed fontSize="small" style={{ color: theme.palette.common.white }} />,
                progress: getProgressStepBySlug('technical-itinerary')?.progress_percentage ?? 0,
                path: encodingRoutesConfig.TECHNICAL_ITINERARY,
                isVisible: getProgressStepBySlug('technical-itinerary')?.is_visible ?? false,
            },
            {
                status: getProgressStepStatus(PROGRESS_SLUGS.DRAINAGE),
                title: t('encoding-layout.nav.drainage'),
                startIcon: <IconLayersTwo1 fontSize="small" style={{ color: theme.palette.common.white }} />,
                progress: getProgressStepBySlug(PROGRESS_SLUGS.DRAINAGE)?.progress_percentage ?? 0,
                path: encodingRoutesConfig.DRAINAGE,
                isVisible: getProgressStepBySlug(PROGRESS_SLUGS.DRAINAGE)?.is_visible ?? false,
            },
            {
                status: getProgressStepStatus(PROGRESS_SLUGS.IRRIGATION),
                title: t('encoding-layout.nav.irrigation'),
                startIcon: <IconFertilizer fontSize="small" style={{ color: theme.palette.common.white }} />,
                progress: getProgressStepBySlug(PROGRESS_SLUGS.IRRIGATION)?.progress_percentage ?? 0,
                path: encodingRoutesConfig.IRRIGATION,
                // SP-4185 - Irrigation is not part of the MVP
                // isVisible: getProgressStepBySlug('irrigation')?.is_visible ?? false,
                isVisible: getProgressStepBySlug(PROGRESS_SLUGS.IRRIGATION)?.is_visible ?? false,
            },
            {
                status: getProgressStepBySlug('agroforestry')?.status ?? 'not-started',
                title: t('encoding-layout.nav.agroforestry'),
                startIcon: <IconCoverCrop fontSize="small" style={{ color: theme.palette.common.white }} />,
                progress: getProgressStepBySlug('agroforestry')?.progress_percentage ?? 0,
                path: encodingRoutesConfig.AGROFORESTRY,
                // SP-4185 - Agroforestry is not part of the MVP
                // isVisible: getProgressStepBySlug('agroforestry')?.is_visible ?? false,
                isVisible: false,
            },
            {
                status: getProgressStepStatus(PROGRESS_SLUGS.LIVESTOCK),
                title: t('encoding-layout.nav.livestock'),
                subtitle: '',
                startIcon: <IconCow fontSize="small" style={{ color: theme.palette.common.white }} />,
                progress: getProgressStepBySlug(PROGRESS_SLUGS.LIVESTOCK)?.progress_percentage ?? 0,
                path: encodingRoutesConfig.LIVESTOCK,
                isVisible: getProgressStepBySlug(PROGRESS_SLUGS.LIVESTOCK)?.is_visible ?? false,
            },
            {
                status: getProgressStepBySlug('soil-analysis')?.status ?? 'not-started',
                title: t('encoding-layout.nav.soil-analysis'),
                subtitle: '',
                startIcon: <IconMicroscope fontSize="small" style={{ color: theme.palette.common.white }} />,
                progress: getProgressStepBySlug('soil-analysis')?.progress_percentage ?? 0,
                path: encodingRoutesConfig.SOIL_ANALYSIS,
                // SP-4185 - Soil analysis is not part of the MVP
                // isVisible: getProgressStepBySlug('soil-analysis')?.is_visible ?? false,
                isVisible: false,
            },
            {
                status: getProgressStepStatus(PROGRESS_SLUGS.HISTORY),
                title: t('encoding-layout.nav.history'),
                subtitle: '',
                startIcon: <IconHistory fontSize="small" style={{ color: theme.palette.common.white }} />,
                progress: getProgressStepBySlug(PROGRESS_SLUGS.HISTORY)?.progress_percentage ?? 0,
                path: encodingRoutesConfig.HISTORY,
                isVisible: getProgressStepBySlug(PROGRESS_SLUGS.HISTORY)?.is_visible ?? false,
            },
        ];
        return navItemsArray;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [theme.palette.common.white, getProgressStepBySlug, currentSeasonId]);

    return (
        <div className={classes.encodingNav}>
            <div className={classes.nav}>
                <div className={classes.selectSeasons}>
                    <SelectSeasons />
                </div>
                <div className={classes.title}>
                    <Typography color={theme.palette.primaryScale[300]} variant="caps">
                        {t('encoding.nav.title')}
                    </Typography>
                </div>
                <div className={classes.list}>
                    {navItems.map((navItem) => (
                        <NavItem
                            key={navItem.title}
                            title={navItem.title}
                            subtitle={navItem.subtitle}
                            startIcon={navItem.startIcon}
                            status={navItem.status}
                            progress={navItem.progress}
                            path={navItem.path}
                            isVisible={navItem.isVisible}
                        />
                    ))}
                </div>
            </div>

            <NextSteps displayCertificatesSection={!isBaseline} />

            {/* -------- SKELETON -------- */}
            <div
                className={`${classes.skeletonSection} ${isLoading ? classes.skeletonSectionLoading : ''} `}
                data-testid="nav-skeleton-section"
            >
                <Skeleton sx={{ bgcolor: skeletonBgColor, width: '90%' }}>
                    <Typography color="white" variant="caps" p={2} component="div">
                        {t('encoding.nav.title')}
                    </Typography>
                </Skeleton>

                <Skeleton sx={{ bgcolor: skeletonBgColor, minWidth: '70%' }}>
                    <Box height="52px" mb={1}>
                        <Typography variant="caps" color="white" display="block">
                            Menu Item 1
                        </Typography>
                    </Box>
                </Skeleton>

                <Skeleton sx={{ bgcolor: skeletonBgColor, minWidth: '70%' }}>
                    <Box height="52px" mb={1}>
                        <Typography variant="caps" color="white">
                            Menu Item 2
                        </Typography>
                    </Box>
                </Skeleton>

                <Skeleton sx={{ bgcolor: skeletonBgColor, minWidth: '70%' }}>
                    <Box height="52px" mb={1}>
                        <Typography variant="caps" color="white">
                            Menu Item 3
                        </Typography>
                    </Box>
                </Skeleton>

                <Skeleton sx={{ bgcolor: skeletonBgColor, minWidth: '70%' }}>
                    <Box height="52px" mb={1}>
                        <Typography variant="caps" color="white">
                            Menu Item 4
                        </Typography>
                    </Box>
                </Skeleton>

                <Skeleton sx={{ bgcolor: skeletonBgColor, minWidth: '70%' }}>
                    <Box height="52px" mb={1}>
                        <Typography variant="caps" color="white">
                            Menu Item 5
                        </Typography>
                    </Box>
                </Skeleton>
            </div>
        </div>
    );
};
