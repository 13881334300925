import { useNavigate, useRoutes } from 'react-router-dom';
import { irrigationRoutesConfig } from './irrigation.routes';
import { IntroductionContentPage } from './pages/IntroductionContentPage/IntroductionContentPage';
import { encodingShared } from '@modules/encoding/shared';
import FieldIdentityContextualPanel from './pages/FieldIdentityContextualPanel/FieldIdentityContextualPanel';
import IrrigationFormActionPage from './pages/IrrigationFormActionPage/IrrigationFormActionPage';
import { IrrigationDuplicationActionPage } from './pages/IrrigationDuplicationActionPage/IrrigationDuplicationActionPage';
import { Guard } from '@shared/guards/Guard';
import { IsStepVisibleGuard } from '@shared/guards/IsStepVisibleGuard';
import { ArePreviousStepCompletedGuard } from '@shared/guards/ArePreviousStepCompletedGuard';

export default () => {
    const navigate = useNavigate();

    const routes = useRoutes([
        {
            path: irrigationRoutesConfig.introduction,
            element: (
                <Guard
                    element={<IntroductionContentPage navigate={navigate} />}
                    guards={[IsStepVisibleGuard, ArePreviousStepCompletedGuard]}
                />
            ),
        },
        {
            path: irrigationRoutesConfig.irrigation.base,
            element: <FieldIdentityContextualPanel navigate={navigate} />,
            children: [
                {
                    path: irrigationRoutesConfig.irrigation.form,
                    element: (
                        <Guard
                            element={<IrrigationFormActionPage navigate={navigate} />}
                            guards={[IsStepVisibleGuard, ArePreviousStepCompletedGuard]}
                        />
                    ),
                },
                {
                    path: irrigationRoutesConfig.irrigation.duplication,
                    element: (
                        <Guard
                            element={<IrrigationDuplicationActionPage navigate={navigate} />}
                            guards={[IsStepVisibleGuard, ArePreviousStepCompletedGuard]}
                        />
                    ),
                },
            ],
        },
    ]);

    return (
        <encodingShared.FadeTransition transitionKey={routes?.props?.match?.pathnameBase as string | undefined}>
            {routes}
        </encodingShared.FadeTransition>
    );
};
