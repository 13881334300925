/* eslint-disable @typescript-eslint/no-explicit-any */
import { EntityAdapter, EntityState } from '@reduxjs/toolkit';
import { TagNameT } from './entityApi';
import EntityError from './EntityError';
import getOptimisticVirtualId from './getOptimisticVirtualId';

export type ApiContextT = { queryFulfilled: Promise<{ data: any; meta: any }>; dispatch: any };

/** `fetch-only`: no optimistic response. (the default one) */
export type FetchOnlyT = 'fetch-only';
/** `cache-only`: only mutate the cache. Still wait the fetch but only to undo on error.\ */
export type CacheOnlyT = 'cache-only';
/** `cache-then-fetch`: mutate the cache first, then wait the fetch to mutate again or undo on error */
export type CacheThenFetchT = 'cache-then-fetch';

/**
 * P: EndpointParamsT, E: entity, T: Tag
 * @param api
 * @param tagName
 * @param cacheAdapter
 * @returns
 */
export default <P extends Record<string, number | undefined>, E extends { id: number }, T extends TagNameT>(
    api: any,
    tagName: T,
    cacheAdapter: EntityAdapter<E>,
) => ({
    transformResponse: (response: E[]) => cacheAdapter.setAll(cacheAdapter.getInitialState(), response),

    /**
     *
     * @param params
     * @param apiContext
     * @param optimisticStrategy {@link OptimisticStrategyT}
     */
    add: <AllowedStrategyT extends CacheOnlyT | CacheThenFetchT | void = void>(
        optimisticStrategy: AllowedStrategyT | FetchOnlyT = 'fetch-only',
    ) => {
        return async ({ body, ...endpointParams }: P & { body?: Partial<E> }, apiContext: ApiContextT) => {
            // TODO Could we use the schema there to make sur the data are safe in createCache? -> This implies body properties to match resource
            if (optimisticStrategy === 'cache-then-fetch' && !body) {
                throw new EntityError(tagName, 'cache', 'try to add optimistic but no body passed');
            }
            if (optimisticStrategy === 'cache-only' && !body?.id) {
                throw new EntityError(tagName, 'cache', 'try to add permanent optimistic but no id');
            }

            const optimisticVirtualId = getOptimisticVirtualId();

            const createCache = (entity: E) =>
                apiContext.dispatch(
                    api.util.updateQueryData(`get${tagName}`, endpointParams, (draft: EntityState<E>) => {
                        cacheAdapter.addOne(draft, entity);
                    }),
                );

            if (optimisticStrategy === 'cache-only' || optimisticStrategy === 'cache-then-fetch') {
                const { undo } = createCache({ id: optimisticVirtualId, ...body } as E);
                apiContext.queryFulfilled
                    .then(({ data }) => {
                        if (optimisticStrategy === 'cache-then-fetch') {
                            // remove Optimistic Cache
                            apiContext.dispatch(
                                api.util.updateQueryData(`get${tagName}`, endpointParams, (draft: EntityState<E>) => {
                                    cacheAdapter.removeOne(draft, optimisticVirtualId);
                                }),
                            );
                            createCache(data);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        undo();
                    });
            }

            if (optimisticStrategy === 'fetch-only') {
                apiContext.queryFulfilled
                    .then(({ data }) => {
                        createCache(data);
                    })
                    .catch((e) => console.error(e)); // the catch is required to avoid uncaught promise.then() for errors from .unwrap()...
            }
        };
    },

    update: <AllowedStrategyT extends CacheOnlyT | CacheThenFetchT | void = void>(
        optimisticStrategy: AllowedStrategyT | FetchOnlyT = 'fetch-only',
    ) => {
        return async (
            { id, body, ...endpointParams }: P & { body: Partial<E>; id: number },
            apiContext: ApiContextT,
        ) => {
            const updateCache = (changes: Partial<E>) =>
                apiContext.dispatch(
                    api.util.updateQueryData(`get${tagName}`, endpointParams, (draft: EntityState<E>) => {
                        cacheAdapter.updateOne(draft, { changes, id });
                    }),
                );

            if (optimisticStrategy === 'cache-only' || optimisticStrategy === 'cache-then-fetch') {
                const { undo } = updateCache(body);
                apiContext.queryFulfilled
                    .then(({ data }) => {
                        if (optimisticStrategy === 'cache-then-fetch') {
                            updateCache(data);
                        }
                    })
                    .catch(() => {
                        undo();
                    });
            }

            if (optimisticStrategy === 'fetch-only') {
                const { data } = await apiContext.queryFulfilled;
                updateCache(data);
            }
        };
    },

    remove: <AllowedStrategyT extends CacheOnlyT | void = void>(
        optimisticStrategy: AllowedStrategyT | FetchOnlyT = 'fetch-only',
    ) => {
        return async ({ id, ...endpointParams }: P & { id: number }, apiContext: ApiContextT) => {
            const removeFromCache = () =>
                apiContext.dispatch(
                    api.util.updateQueryData(`get${tagName}`, endpointParams, (draft: EntityState<E>) => {
                        cacheAdapter.removeOne(draft, id);
                    }),
                );

            if (optimisticStrategy === 'cache-only') {
                const { undo } = removeFromCache();
                apiContext.queryFulfilled.catch(undo);
            } else if (optimisticStrategy === 'fetch-only') {
                await apiContext.queryFulfilled;
                removeFromCache();
            }
        };
    },

    removeWhere: <AllowedStrategyT extends CacheOnlyT | void = void>(
        key: keyof E,
        value: number,
        optimisticStrategy: AllowedStrategyT | FetchOnlyT = 'fetch-only',
    ) => {
        return async (endpointParams: P, apiContext: ApiContextT) => {
            const removeFromCache = () =>
                apiContext.dispatch(
                    api.util.updateQueryData(`get${tagName}`, endpointParams, (draft: EntityState<E>) => {
                        const entitiesToRemove = draft.ids
                            .map((id) => draft.entities[id])
                            .filter((e: any) => typeof value === 'number' && e?.[key] === value)
                            .filter((e) => !!e) as E[];
                        cacheAdapter.removeMany(
                            draft,
                            entitiesToRemove.map((e) => e.id),
                        );
                    }),
                );

            if (optimisticStrategy === 'cache-only') {
                const { undo } = removeFromCache();
                apiContext.queryFulfilled.catch(undo);
            } else if (optimisticStrategy === 'fetch-only') {
                await apiContext.queryFulfilled;
                removeFromCache();
            }
        };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    upsertMany: (_optimisticStrategy: CacheOnlyT = 'cache-only') => {
        return async ({ entities, ...endpointParams }: P & { entities: E[] }, apiContext: ApiContextT) => {
            apiContext.dispatch(
                api.util.updateQueryData(`get${tagName}`, endpointParams, (draft: EntityState<E>) => {
                    cacheAdapter.upsertMany(draft, entities);
                }),
            );
        };
    },
});
