import { Navigate } from 'react-router-dom';
import { PropsWithChildren, ReactElement } from 'react';

export type GuardT = PropsWithChildren<{
    redirectUrl: string;
    /** display a `Loader` component while checking the guard (=> passed != undefined) \
     * `null` will display nothing while checking the guard \
     * `false` (default) will display the children while checking the guard. Then redirect if passed = false
     */
    Loader?: ReactElement | null | false;
}>;

type BaseGuardProps = GuardT & {
    passed: boolean | undefined;
};

export const BaseGuard = ({ passed, redirectUrl, children, Loader = false }: BaseGuardProps) => {
    const isLoading = passed === undefined;
    if (isLoading && Loader !== false) {
        return Loader || null;
    }
    if (passed === false) {
        return <Navigate to={redirectUrl} />;
    }

    return <>{children}</>;
};
