import { useGlobalSaveStatusData } from '@modules/encoding/modules/layout/components/GlobalSaveStatus/useGlobalSaveStatus.data';
import { useGlobalSaveStatusLogic } from '@modules/encoding/modules/layout/components/GlobalSaveStatus/useGlobalSaveStatus.logic';
import entities from '@shared/entities';

export const useFarmProfileProgress = ({ farmSeasonId }: { farmSeasonId: number }) => {
    const dataContext = useGlobalSaveStatusData();
    const { globalMutationStatus } = useGlobalSaveStatusLogic({ dataContext });
    const isPerformingMutation = globalMutationStatus === 'pending';

    const { progressState } = entities.progress.useState({ seasonId: farmSeasonId }, { skip: !farmSeasonId });
    const hasCompleteFarmProfile = progressState.getBySlug('farm-profile')?.progress_done === 1;

    const isLoading = progressState.isFetching || isPerformingMutation;

    return { canContinue: !isPerformingMutation && hasCompleteFarmProfile, isLoading };
};
